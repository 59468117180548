.image-slider-container {
  width: 80%; /* Imposta la larghezza al 100% della larghezza della viewport */
  height: 100%; /* Imposta l'altezza al 100% dell'altezza della viewport */
  
}

.slick-track {
  width: 80%;
  height: 100vh;
  background-color: transparent;
}
/* the slides */
.slick-slide {
  margin: 0 0px;
  width: 80%; 
  height: 100vh;/* Imposta la larghezza al 100% della larghezza della viewport */
  background-color: transparent;
  
}
.slick-slider, .slick-list, .slick-track, .slick-slide {
  height: 100% !important;
}
.slick-slider {
  margin: 0 0px;
  height: 100vh; /* Imposta la larghezza al 100% della larghezza della viewport */
  
}
/* the parent */
.slick-list {
  margin-left: auto;
  margin-right: auto;
  width: 100%; /* Adjust this value to control how much of the next slide is visible */
  height: 100vh;
}

.slick-dots li:nth-child(1) button:before {
  content: ""; /* Crea un nuovo elemento :after */
  display: inline-block;
  width: 22px; /* Imposta la larghezza del punto */
  height: 22px; /* Imposta l'altezza del punto */
  background: linear-gradient(
    180deg,
    #00858f 0%,
    #002629 100%
  ); /* Imposta il gradiente di colore come sfondo */
  border-radius: 50%; /* Rendi il punto rotondo */
  opacity: 0.5;
}

.slick-dots li:nth-child(2) button:before {
  content: ""; /* Crea un nuovo elemento :after */
  display: inline-block;
  width: 22px; /* Imposta la larghezza del punto */
  height: 22px; /* Imposta l'altezza del punto */
  background: linear-gradient(
    180deg,
    rgba(230, 0, 126, 0.3) 0%, 
    rgba(128, 0, 70, 0.3) 100%

  );
  border-radius: 50%; /* Rendi il punto rotondo */
  opacity: 0.5;
}

.slick-dots li:nth-child(3) button:before {
  content: ""; /* Crea un nuovo elemento :after */
  display: inline-block;
  width: 22px; /* Imposta la larghezza del punto */
  height: 22px; /* Imposta l'altezza del punto */
  background: linear-gradient(
    180deg,
    rgba(117, 70, 150, 1)
 0%,
 rgba(37, 22, 48, 1) 100%
  );
  border-radius: 50%; /* Rendi il punto rotondo */
  opacity: 0.5;
}


.slick-dots li:nth-child(4) button:before {
  content: ""; /* Crea un nuovo elemento :after */
  display: inline-block;
  width: 22px; /* Imposta la larghezza del punto */
  height: 22px; /* Imposta l'altezza del punto */
  background: linear-gradient(
    180deg,
    rgba(230, 0, 126, 1) 0%,
 rgba(128, 0, 70, 1) 100%
  );
  border-radius: 50%; /* Rendi il punto rotondo */
  opacity: 0.5;
}

.slick-dots li:nth-child(5) button:before {
  content: ""; /* Crea un nuovo elemento :after */
  display: inline-block;
  width: 22px; /* Imposta la larghezza del punto */
  height: 22px; /* Imposta l'altezza del punto */
  background: linear-gradient(
    180deg,
    #00858f 0%,
    #002629 100%
  );
  border-radius: 50%; /* Rendi il punto rotondo */
  opacity: 0.5;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
}

@media (max-width: 600px) {
  .slick-dots {
   visibility: hidden;
  }
}
