/* For Chrome, Safari and Opera */
::-webkit-scrollbar {
    width: 12px;
    background: black; /* This will make the scrollbar black */
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    background: white; /* This will make the slider white */
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

/* For Internet Explorer and Edge */
body {
    scrollbar-face-color: white; /* This will make the slider white */
    scrollbar-track-color: black; /* This will make the scrollbar black */
}


:root {
    --gradient-border: linear-gradient(90deg, #00858F 0%, #E6007E 100%);
}

/* This class is to hide overflow and show the pseudo-element */
.gradient-border-parent {
    overflow: hidden;
    position: relative;
}


.with-border {
    border-color: white;
    border-style: solid;
    border-width: 3px;
    border-image: var(--gradient-border) 1;

}

.gradient-border {
    position: relative;
    background: transparent;
}

.border-gradient-purple {
    border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
}

.border-gradient {
    border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
}
.border-gradient-purple {
    border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
}